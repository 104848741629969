//Import NPM Packages.
import axios from 'axios';
import { API_URL } from '../utils/constant';
import { logout, accessToken } from '../utils/functions';
import { message } from 'antd';

//Request Middleware.
function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data);
    } catch (e) {
      if (e.response?.status === 401) {
        if (e.response.data.message === 'Unauthenticated.') {
          logout();
          window.location.href = '/login';
        } else if (e.response.data.message !== "" && e.response.data.message === "These credentials do not match our records.") {
          resolve(e.response);
        } else {
          message.error({ content: "Permission Denied", className: 'error-class' });
        }
        resolve("Permission Denied");
      } else if (e.response?.status === 403) {
        window.location.href = '/unauthorized';
      }
      else if (e.response?.status === 503) {
        resolve(e.response);
      } else if (e.response?.status === 404) {
        resolve("something went wrong!");
      } else {
        resolve(e.response?.data || "something went wrong!");
      }

    }
  });
}

export function getRequest(path, params) {
  return makeHttpRequest(() => axios.get(`${API_URL}${path}`, {
    params,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function postRequest(path, data, options) {
  return makeHttpRequest(() => axios.post(`${API_URL}${path}`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function patchRequest(path, data, options) {
  return makeHttpRequest(() => axios.patch(`${API_URL}${path}`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function putRequest(path, data, options) {
  return makeHttpRequest(() => axios.put(`${API_URL}${path}`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function deleteRequest(path, data, options) {
  return makeHttpRequest(() => axios.delete(`${API_URL}${path}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}